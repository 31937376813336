import React from "react";
import { useLocation, useNavigation } from "@remix-run/react";
import debounce from "lodash/debounce";

// https://usehooks.com/useWindowSize
export interface Size {
	width?: number;
	height?: number;
}

export function useWindowSize() {
	const [windowSize, setWindowSize] = React.useState<Size>({
		width: undefined,
		height: undefined,
	});

	const handleResize = () => {
		setWindowSize({
			width: window.innerWidth,
			height: window.innerHeight,
		});
	};

	React.useLayoutEffect(() => {
		window.addEventListener("resize", handleResize);
		handleResize();
		return () => window.removeEventListener("resize", handleResize);
	}, []);

	return windowSize;
}

export function useMobile() {
	const windowSize = useWindowSize();
	if (!windowSize.width) return;
	return windowSize.width < 768 || navigator.userAgent.indexOf("Mobi") > -1;
}

export function usePageLeave(callBack: () => void, enabled = true) {
	const didRun = React.useRef(false);

	const navigation = useNavigation();
	const location = useLocation();
	const isNavigating =
		navigation.state === "loading" &&
		navigation.location.pathname !== location.pathname;

	const runCallback = React.useCallback(() => {
		if (!enabled || didRun.current) {
			return;
		}

		callBack();
		didRun.current = true;
	}, [callBack, enabled, didRun]);

	// users navigate to another internal page
	React.useEffect(() => {
		if (isNavigating) {
			runCallback();
		}
	}, [isNavigating, runCallback]);

	// users refresh page, close browser tab, etc
	React.useEffect(() => {
		window.addEventListener("beforeunload", () => runCallback());
		return () => {
			window.removeEventListener("beforeunload", () => runCallback());
		};
	}, [runCallback]);
}

export function useUpdateQueryStringValueWithoutNavigation(
	queryKey: string,
	queryValue: string,
	options?: {
		enabled: boolean;
	}
) {
	const { enabled = true } = options ?? {};

	React.useEffect(() => {
		if (!enabled) {
			return;
		}

		const currentSearchParams = new URLSearchParams(window.location.search);
		const oldQuery = currentSearchParams.get(queryKey) ?? "";
		if (queryValue === oldQuery) return;

		if (queryValue) {
			currentSearchParams.set(queryKey, queryValue);
		} else {
			currentSearchParams.delete(queryKey);
		}

		const newUrl = [window.location.pathname, currentSearchParams.toString()]
			.filter(Boolean)
			.join("?");

		// We don't want to trigger the navigation update which causes the loader to reload again
		window.history.replaceState(null, "", newUrl);
	}, [queryKey, queryValue, enabled]);
}

export function useKeydown(
	key: string,
	callback: (event: KeyboardEvent) => void
) {
	React.useEffect(() => {
		function handleKeyDown(event: KeyboardEvent) {
			if (event.code === key) {
				callback(event);
			}
		}

		window.addEventListener("keydown", handleKeyDown);

		return () => {
			window.removeEventListener("keydown", handleKeyDown);
		};
	}, [key, callback]);
}

export function useDebounce<
	Callback extends (...args: Parameters<Callback>) => ReturnType<Callback>,
>(callback: Callback, delay: number) {
	const callbackRef = React.useRef(callback);
	React.useEffect(() => {
		callbackRef.current = callback;
	});
	return React.useMemo(
		() =>
			debounce(
				(...args: Parameters<Callback>) => callbackRef.current(...args),
				delay
			),
		[delay]
	);
}

export function scrollIntoViewOffset(element: HTMLElement, offset: number) {
	const elementPosition = element.getBoundingClientRect().top;
	const bodyRect = document.body.getBoundingClientRect().top;
	const position = elementPosition - bodyRect - offset;
	window.scrollTo({
		top: position,
		behavior: "smooth",
	});
}

export function useSmoothScrollToHash(navHeight: number) {
	const location = useLocation();

	React.useEffect(() => {
		const { hash } = location;
		if (hash) {
			const element = document.getElementById(hash.slice(1));
			if (element) {
				scrollIntoViewOffset(element, navHeight);
			}
		}
	}, [location, navHeight]);
}
